<template>
  <div class="container border-bottom-muted mb-3">
    <div class="row">
      <div class="col-md-12 pl-0">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb bg-transparent fw-500 pr-0 pl-0 mb-0 fs-14">
            <template v-for="(breadcrumb, index) in items">
              <li class="breadcrumb-item" v-if="!breadcrumb.active">
                <router-link class="anim-link-2  anim-link-2-primary" :to="breadcrumb.url"><i v-if="breadcrumb.icon != null && breadcrumb.icon != ''" :class="breadcrumb.icon"></i> {{ breadcrumb.name }}</router-link>
              </li>
              <li v-else class="breadcrumb-item active text-light" aria-current="page"> {{ breadcrumb.name }}</li>
            </template>
          </ol>
        </nav>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "app-layout-breadcrumb",
  props: ["items"]
};
</script>
