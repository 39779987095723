<template>
  <section class="container text-transparencia">
    <layout-breadcrumb :items="breadcrumbs"></layout-breadcrumb>
    <div class="row">
      <div class="col-sm-12">
        <h2 class="font-weight-800 text-transparencia">
          <!-- <a @click="$router.go(-1)"><i class="fa fa-arrow-left cr-po mr-5" data-toggle="tooltip" data-placement="top" title="Volver"></i></a> -->
          Transparencia
        </h2>
        <p class="mt-1 mb-3 text-transparencia">Seleccione una categoría</p>
      </div>
    </div>

    <div class="row">
      <div class="col-md-3 col-sm-4  col-lg-2  mt-2">
        <button class="btn btn-block transition-hover px-3 rounded-pill cr-po" :class="categoriaActual == 'DOC' ? 'btn-secondary' : 'btn-transparencia'" v-on:click="mostrarPanel('DOC')">Documentos</button>
      </div>
      <div class="col-md-6 col-sm-8  col-lg-3  mt-2">
        <button class="btn btn-block transition-hover px-3 rounded-pill cr-po" :class="categoriaActual == 'DEC' ? 'btn-secondary' : 'btn-transparencia'" v-on:click="mostrarPanel('DEC')">Declaración Jurada de Intereses</button>
      </div>
      <div class="col-md-3 col-sm-4  col-lg-2  mt-2">
        <button class="btn btn-block transition-hover px-3 rounded-pill cr-po" :class="categoriaActual == 'PRO' ? 'btn-secondary' : 'btn-transparencia'" v-on:click="mostrarPanel('PRO')">Proyectos</button>
      </div>
      <div class="col-md-4 col-sm-4  col-lg-3  mt-2">
        <button class="btn btn-block transition-hover px-3 rounded-pill cr-po" :class="categoriaActual == 'INF' ? 'btn-secondary' : 'btn-transparencia'" v-on:click="mostrarPanel('INF')">Informes de Auditoría</button>
      </div>
      <div class="col-md-3 col-sm-4  col-lg-2  mt-2">
        <button class="btn btn-block transition-hover px-3 rounded-pill cr-po" :class="categoriaActual == 'DON' ? 'btn-secondary' : 'btn-transparencia'" v-on:click="mostrarPanel('DON')">Donantes</button>
      </div>
    </div>

    <div class="row justify-content-center pt-5" v-if="categoriaActual == 'DOC'">
      <template v-for="(documento, key) in documentos">
        <div class="col-md-4 col-sm-6 col-xs-12 mt-2" :key="key">
          <div class="card d-block card-frame card-owework  border hover-shadow-6 d-block transition-hover">
            <div class="card-img-top text-center">
              <img class="img-fluid w-100 image-round-top cimage-slider" :src="`${fileUrl(documento.rutaImagen)}`" :alt="documento.titulo" />
            </div>
            <div class="card-body p-3">
              <h5 class="card-title mb-3 fs-15 text-center text-transparencia">{{ documento.titulo }}</h5>
              <div class="text-center">
                <a class="btn btn-transparencia btn-sm fw-600 text-center pl-5 pr-5" :href="`${filesUrl + documento.rutaArchivo}`" download target="_blank"><i class="fa fa-download"></i> DESCARGAR</a>
              </div>
            </div>
          </div>
        </div>
      </template>
    </div>

    <div class="row justify-content-center pt-5" v-if="categoriaActual == 'DEC'">
      <template v-for="(declaracion, index) in declaraciones">
        <div class="col-lg-2 col-md-3 col-sm-4 col-xs-12  m-3" :key="index">
          <div class="card d-block border-0" style="background-color:transparent !important;">
            <div class="card-img-top text-center">
              <img class="img-fluid w-100  image-round-all" :src="`${fileUrl(declaracion.rutaImagen)}`" :alt="declaracion.nombresCompletos" />
            </div>
            <div class="card-body p-2">
              <span class="card-title fs-14 mb-0 w-100 font-weight-600 text-center text-transparencia" style="display:block">{{ declaracion.nombresCompletos }}</span>
              <p class="text-center fs-13 text-transparencia" v-if="declaracion.cargo != null && declaracion.cargo != ''">{{ declaracion.cargo }}</p>
              <div class="text-center">
                <a class="btn btn-transparencia btn-sm fw-600 text-center pl-5 pr-5" target="_blank" :href="`${filesUrl + declaracion.rutaArchivo}`" download><i class="fa fa-download"></i> DESCARGAR</a>
              </div>
            </div>
          </div>
        </div>
      </template>
    </div>

    <div class="row justify-content-center" v-if="categoriaActual == 'PRO'">
      <div class="d-flex justify-content-center mt-5 w-100">
        <div class="p-4"><button class="btn btn-block transition-hover px-3 rounded-pill" :class="categoriaActualProyecto == 'EJE' ? 'btn-secondary' : 'btn-transparencia'" v-on:click="mostrarPanelProyectos('EJE')">Proyectos en Ejecución</button></div>
        <div class="p-4"><button class="btn btn-block transition-hover px-3 rounded-pill" :class="categoriaActualProyecto == 'FIN' ? 'btn-secondary' : 'btn-transparencia'" v-on:click="mostrarPanelProyectos('FIN')">Proyectos Anteriores</button></div>
      </div>
      <div class="container">
        <div class="row justify-content-center pt-5" v-if="categoriaActualProyecto == 'EJE'">
          <template v-for="(proyecto, index) in proyectos.ejecutados">
            <div class="col-md-8 col-xs-12 bg-card-green p-5 image-round-all">
              <h5 class="font-weight-700 text-dark text-center">{{ proyecto.titulo }}</h5>
              <div class="text-transparencia mt-3">
                <strong>Objetivos</strong>
                <p>{{ proyecto.objetivos }}</p>
              </div>
              <div class="row align-items-center mt-2">
                <div class="col-md-8">
                  <strong>Agencia cooperante</strong>
                  <p class=" text-transparencia fs-16 mb-1 mt-1">
                    {{ proyecto.agenciaCooperante }}
                  </p>
                </div>
                <div class="col-md-4 text-center">
                  <img alt="" class="img-fluid mt-2" :src="`${fileUrl(proyecto.rutaImagen)}`" :alt="proyecto.titulo" style="height:130px;width:auto;" />
                </div>
              </div>
              <div class="row align-items-center mt-2 text-transparencia">
                <div class="col-md-6">
                  <strong>Período</strong>
                  <p class="text-transparencia">{{ proyecto.periodoInicio }} - {{ proyecto.periodoFin }}</p>
                </div>
                <div class="col-md-6">
                  <strong>Monto</strong>
                  <p class="text-transparencia">{{ proyecto.descripcionMoneda }} {{ proyecto.monto }}</p>
                </div>
              </div>
            </div>
          </template>
        </div>
        <div class="row justify-content-center pt-5" v-if="categoriaActualProyecto == 'FIN'">
          <template v-for="(proyecto, index) in proyectos.finalizados">
            <div class="col-md-8 col-xs-12 bg-card-green p-5 image-round-all">
              <h5 class="font-weight-700 text-dark text-center">{{ proyecto.titulo }}</h5>
              <div class="text-transparencia mt-3">
                <strong>Objetivos</strong>
                <p>{{ proyecto.objetivos }}</p>
              </div>
              <div class="row align-items-center mt-2">
                <div class="col-md-8">
                  <strong>Agencia cooperante</strong>
                  <p class=" text-transparencia fs-16 mb-1 mt-1">
                    {{ proyecto.agenciaCooperante }}
                  </p>
                </div>
                <div class="col-md-4 text-center">
                  <img alt="" class="img-fluid mt-2" :src="`${fileUrl(proyecto.rutaImagen)}`" :alt="proyecto.titulo" style="height:130px;width:auto;" />
                </div>
              </div>
              <div class="row align-items-center mt-2 text-transparencia">
                <div class="col-md-6">
                  <strong>Período</strong>
                  <p class="text-transparencia">{{ proyecto.periodoInicio }} - {{ proyecto.periodoFin }}</p>
                </div>
                <div class="col-md-6">
                  <strong>Monto</strong>
                  <p class="text-transparencia">{{ proyecto.descripcionMoneda }} {{ proyecto.monto }}</p>
                </div>
              </div>
            </div>
          </template>
        </div>
      </div>
    </div>

    <div class="pt-5" v-if="categoriaActual == 'INF'">
      <template v-for="(informe, index) in informes">
        <div class="row justify-content-center align-items-center pl-4 pr-4 pb-4 border-bottom">
          <div class="col-md-10 mt-3">
            <div class="card border-0" style="background-color:transparent  !important">
              <div class="media fw-400">
                <div class="media-body">
                  <strong class="d-block text-dark text-dark fw-600">{{ informe.anioPublicacion }}</strong>
                  <h6 class="d-block mb-2 text-transparencia fw-600">{{ informe.titulo }}</h6>
                  <small class="d-block fs-15 text-transparencia mt-3">Estados financieros al {{ informe.periodoInicio }} al {{ informe.periodoFin }} </small>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-2 text-center">
            <a class="btn btn-transparencia btn-sm transition-hover mt-2 px-3 btn-round " target="_blank" :href="`${filesUrl + informe.rutaArchivo}`" download><i class="fa fa-download"></i> DESCARGAR</a>
          </div>
        </div>
      </template>
    </div>

    <div class="pt-5" v-if="categoriaActual == 'DON'">
      <h5 class="mt-6 text-dark fw-600">Agencias de cooperación internacional</h5>
      <div id="" class="bg-white">
        <div class="card border-0 d-flex justify-content-center" style="height:220px;">
          <infinite-slide-bar>
            <template v-for="donante in donantes.internacional" >
              <img alt="" style="padding-left:20px;padding-right:20px; height:90px;width: auto;" class="img-fluid border-0" :src="`${filesUrl + donante.rutaImagen}`" />
            </template>
          </infinite-slide-bar>
        </div>
      </div>
      <h5 class="mt-7 text-dark fw-600">Empresas auspiciadoras</h5>
      <div class="row ">
        <div class="col-md-12">
          <div class="table-responsive">
            <table class="table table-condensed table-bordered">
              <thead>
                <tr class=" bg-transparencia text-white fs-18">
                  <th class="col-md-5">Empresa</th>
                  <template v-for="(anio, index) in donantes.anios">
                    <th :key="index" class="text-center col-md-1">{{ anio }}</th>
                  </template>
                </tr>
              </thead>
              <tbody>
                <template v-for="(nacional, index) in donantes.nacional">
                  <tr :key="index">
                    <td>
                      <span class="text-transparencia fw-600"> {{ nacional.empresa }}</span>
                    </td>
                    <template v-for="(anio, indexAnio) in nacional.listAnios">
                      <td v-if="anio.monto != null" class=" col-md-1 text-center text-transparencia bg-donante fw-600" :key="indexAnio">
                        <!-- <span class="small fw-600">{{anio.mes}}</span><br>
                      <span>{{ formatPrice(anio.monto) }}</span> -->
                      </td>
                      <td v-else></td>
                    </template>
                  </tr>
                </template>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import layoutBreadcrumb from "@/layout/layout-breadcrumb";
import InfiniteSlideBar from "vue-infinite-slide-bar";

export default {
  name: "view-transparencia",
  metaInfo: {
    title: "Transparencia",
  },
  components: { layoutBreadcrumb, InfiniteSlideBar },
  data: () => ({
    categoriaActual: "DOC",
    categoriaActualProyecto: "EJE",
    lista: [],
    documentos: [],
    declaraciones: [],
    proyectos: [],
    informes: [],
    donantes: {},
  }),
  methods: {
    mostrarPanel: function(codigo) {
      this.categoriaActual = codigo;
      if (this.categoriaActual == "DON") {
        this.setSlide();
      }
    },
    mostrarPanelProyectos: function(codigo) {
      this.categoriaActualProyecto = codigo;
    },
    fetchDatos: async function() {
      await this.$http.get(this.apiRoute("Common", "ListTransparenciaData")).then(function(response) {
        if (!response.body.error) {
          this.documentos = response.body.data.documentos;
          this.declaraciones = response.body.data.declaraciones;
          this.proyectos = response.body.data.proyectos;
          this.informes = response.body.data.informes;
          this.donantes = {
            internacional: response.body.data.donantes.internacionales,
            nacional: response.body.data.donantes.empresasNacionales,
            anios: response.body.data.donantes.aniosCooperacion,
          };
          this.setSlide();
        }
      });
    },
    setSlide: function() {
      startSliderDonantes("slider-donantes", [3, 2, 2, 2, 1, 1]);
    },
  },
  mounted: async function() {
    await this.fetchDatos();

    this.breadcrumbs = [
      { name: "Inicio", url: "/home", icon: "fa fa-home", active: false },
      { name: "Nosotros", url: "/nosotros", icon: "", active: false },
      { name: "Transparencia", url: "/nosotros/transparencia", icon: "", active: true },
    ];
  },
};
</script>
